<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData" style="height: 90vh;">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div  v-else>
      <table-tariff-class-car :tableData="tableData" @openEditModal="openEditModal" @excludedModelCars="excludedModelCars"></table-tariff-class-car>
    </div>
    <modal-tariff-class-car :tariff_id="tariff_id" @refresh="refresh"></modal-tariff-class-car>
    <modal-tariff-class-car-edit :class_car_id="class_car_id" @refresh="refresh"></modal-tariff-class-car-edit>
    <modal-excluded-model-cars :excludedModelDatas="excludedModelDatas" :datas="datas" @refresh="refresh"/>
  </div>  
</template>

<script>
import tableTariffClassCar from "@/views/component/Table/tableTariffClassCar.vue";
import modalTariffClassCar from '@/views/component/Modal/ModalTariffClassCar/modalTariffClassCar.vue';
import modalTariffClassCarEdit from '@/views/component/Modal/ModalTariffClassCar/modalTariffClassCarEdit.vue'
import modalExcludedModelCars from '@/views/component/Modal/ModalTariffClassCar/ModalexcludedModelCars.vue'
export default {
  components: {
    tableTariffClassCar,
    modalTariffClassCar,
    modalTariffClassCarEdit,
    modalExcludedModelCars
  },
  data(){
    return {
      tableData: [],
      showPreloader: false,
      tariff_id: this.$route.query.filter,
      class_car_id: null,
      datas: null,
      excludedModelDatas: []
    }
  },
  methods: {
    refresh(){
      this.showPreloader = true
      this.$http
      .get(`tariff-class-car-settings?tariff_id=${this.$route.query.filter}`)
      .then(res => {
        this.tableData = res.data
        this.showPreloader = false
        this.$store.commit('pageData/setdataCount', this.tableData.length)
      })
      .catch(err => {
      })
    },
    openEditModal(id){
      this.class_car_id = id
      this.$bvModal.show('editClassCar')
    },
    excludedModelCars(data) {
      this.datas = data
      this.$bvModal.show('modalExcludedModelCars')
    },
    excludedModelData() {
      this.$http
          .get('tariff-class-car-settings/data')
          .then((res) => {
            this.excludedModelDatas = res.data.model_cars
          })
    }
  },
  computed: {
    fetchingNewData(){
      return this.$store.state.refresh.fetchingNewData
    }
  },
  mounted(){
    this.refresh()
    this.excludedModelData()
  },
  watch: {
    '$route.query.filter': function(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.showPreloader = true
        this.$http
        .get(`tariff-class-car-settings?tariff_id=${this.$route.query.filter}`)
        .then(res => {
          this.tableData = res.data
          this.showPreloader = false
          this.$store.commit('pageData/setdataCount', this.tableData.length)
        })
      }
    },
    fetchingNewData(val){
      if (val){
        this.$http
        .get(`tariff-class-car-settings?tariff_id=${this.$route.query.filter}`)
        .then(res =>{
          this.tableData = res.data
          this.$store.commit('pageData/setdataCount', this.tableData.length)
          this.$store.commit('REFRESH_DATA', false)
        })
      }
    }
  }
}
</script>

<style>

</style>