<template>
    <b-modal
    id="modalExcludedModelCars"
    title="Добавить"
    size="sm"
    @shown="excludedFilter"
    hide-footer no-enforce-focus
    :no-close-on-backdrop="true"
    @close="closeModal"
    >
    <b-row>
        <b-col class="p-1 m-1 border-color">
            <h4><strong> Исключенный опции: <i class="required">*</i></strong></h4>
            <v-select
                v-model="excluded.excluded_model_cars"
                label="name"
                placeholder="Исключенный опции"
                multiple
                :reduce="options => options.id"
                :options="excludedModelDatas"
                class="select-size-lg"
            />
        </b-col>
    </b-row>
    <b-row class="mt-2 mb-1">
        <b-col class="d-flex justify-content-end">
            <b-button
            :disabled="submitButtonDisabled"
            @click="excludedCar()"
            variant="primary">Добавить</b-button>
            <b-button @click="closeModal()" class="ml-1 mr-1" variant="danger">Отмена</b-button>
        </b-col>
    </b-row>
    </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        vSelect
    },
    props: ['datas', 'excludedModelDatas'],
    data() {
        return {
            excluded: {
                tariff_class_car_id: null,
                excluded_model_cars: []
            },
            submitButtonDisabled: false
        }
    },
    methods: {
        excludedFilter() {
            this.$http
                .get(`tariff-class-car-settings/${this.datas.id}/excluded-model-cars`)
                .then((res) => {
                    if(this.datas.id) this.excluded.tariff_class_car_id = this.datas.id
                    res.data.forEach((e) => {this.excluded.excluded_model_cars.push(e.id)});
                })
        },
        excludedCar() {
            this.submitButtonDisabled = true
            this.$http
                .post(`tariff-class-car-settings/excluded-model-cars`, this.excluded)
                .then((res) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                this.closeModal()
                this.$emit('refresh')
                })
                .catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                })
                .finally(() => {
                    this.submitButtonDisabled = false
                })
        },
        closeModal() {
            this.excluded = {
                tariff_class_car_id: null,
                excluded_model_cars: []
            },
            this.submitButtonDisabled = false,
            this.$bvModal.hide('modalExcludedModelCars')
        }
    },
}
</script>

<style lang="scss" scoped>
.border-color{
    border: 1px solid #ccc;
    border-radius: 5px;
    width: auto;
}
.required {
    color: red;
}
</style>