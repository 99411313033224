<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            head-row-variant="primary"
            :fields="fields"
            :items="tableData"
            @row-clicked="customClicked"
            @row-contextmenu="handleContextMenuEvent"
            @row-dblclicked="edit"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(dop_conditions)="data">
              <span v-for="(dop_condition, idx) in data.item.dop_conditions" :key="idx">
                {{dop_condition ? idx === data.item.dop_conditions.length - 1 ? dop_condition.name : dop_condition.name + '; '  : ''}}
              </span>
            </template>
            <template #cell(not_dop_conditions)="data">
              <span v-for="(not_dop_condition, idx) in data.item.not_dop_conditions" :key="idx">
                {{not_dop_condition ? idx === data.item.not_dop_conditions.length - 1 ? not_dop_condition.name : not_dop_condition.name + '; '  : ''}}
              </span>
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link
                class="d-flex align-items-center"
                @click.prevent="excludedModelCars"
                >
                <feather-icon
                    icon="XIcon"
                />
                <span class="ml-75">Исключенные модели автомобилей</span>
              </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueContext from 'vue-context'
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
  components: {
      VueContext,
  },
  props: ['tableData'],
  data() {
    return {
      dataClass: null,
      fields: [ 
        { key: 'checkbox', label: '', thStyle: {width: '30px'}},
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        { key: "class_car", label: "Класс", sortable: true, thStyle: { width: "135px" } },
        { key: "age_up_to", label: "Возраст до", sortable: true, thStyle: { width: "135px" } },
        { key: "car_condition", label: "Состояние не хуже", sortable: true, thStyle: { width: "135px" } },
        { key: "dop_conditions", label: "Доп. условия", sortable: true, thStyle: { width: "135px" } },
        { key: "not_dop_conditions", label: "Доп. условия(запрещенные)", sortable: true, thStyle: { width: "135px" } },
        { key: "length", label: "Мин. длина", sortable: true, thStyle: { width: "40px" } },
        { key: "width", label: "Мин. ширина", sortable: true, thStyle: { width: "40px" } },
        { key: "height", label: "Мин. высота", sortable: true, thStyle: { width: "40px" } },
        { key: "carrying_capacity", label: "Мин. грузоподъёмность", sortable: true, thStyle: { width: "40px" } },
        { key: "min_count_seat", label: "Мин. число мест", sortable: true, thStyle: { width: "40px" } },
        { key: "setting_intercity_value", label: "Настройки межгорода", sortable: true, thStyle: { width: "135px" } },
        // { key: "drivers", label: "Водители", sortable: true, thStyle: { width: "135px" } },
        // { key: "excluded_model_cars", label: "Исключенные модели", sortable: true, thStyle: { width: "135px" } },
      ],
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    customClicked() {
      cellSelect()
    },
    handleContextMenuEvent(item, index, event){
        event.preventDefault();
        this.$refs.menu.open(event)
        this.dataClass = item
    },
    edit(item){
      this.$emit('openEditModal', item.id)
    },
    excludedModelCars() {
      this.$emit('excludedModelCars', this.dataClass)
    }
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.my-card {
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
table{
    outline: none; 
    table-layout: fixed; 
    -webkit-user-select: none;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
}
</style> 