<template>
  <b-modal
    :id="$route.name + 'CreateModal'"
    title="Добавить"
    size="lg"
    @show="getData"
    @close="closeModal"
    hide-footer
    no-enforce-focus
    :no-close-on-backdrop="true"
  >
  <b-row>
    <b-col cols="4">
      <label for="">Класс автомобиля: <i style="color: red;">*</i></label>
      <v-select
      :options="class_cars"
      :reduce="option => option.id"
      label="name"
      v-model="tariffClassCar.class_car_id"
      ></v-select>
    </b-col>
    <b-col cols="4">
      <label for="">Состояние не хуже: <i style="color: red;">*</i></label>
      <v-select
      :options="car_condition"
      :reduce="option => option.id"
      label="name"
      v-model="tariffClassCar.condition_id"
      ></v-select>
    </b-col>
    <b-col cols="4">
      <label for="">Возраст до: <i style="color: red;">*</i></label>
      <b-input v-model.number="tariffClassCar.age_up_to" type="number"></b-input>
    </b-col>
  </b-row>
  <b-row class="mt-1">
    <b-col cols="5">
      <label for="">Число мест: <i style="color: red;">*</i></label>
      <b-input v-model.number="tariffClassCar.min_count_seat" type="number"></b-input>
    </b-col>
    <b-col cols="5">
      <label for="">Грузоподъёмность: <i style="color: red;">*</i></label>
      <b-input v-model.number="tariffClassCar.carrying_capacity" type="number"></b-input>
    </b-col>
    <b-col cols="2" class="d-flex align-items-center">
        <label for="1" class="check"><input v-model="tariffClassCar.main_class" id="1" style="width: 20px; height: 20px;" type="checkbox">
          <div style="user-select: none; font-size: 16px" class="ml-1">Основной</div>
        </label>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="4">
      <label for="">Длина(м): <i style="color: red;">*</i></label>
      <b-input v-model.number="tariffClassCar.length" type="number"></b-input>
    </b-col>
    <b-col cols="4">
      <label for="">Ширина(м): <i style="color: red;">*</i></label>
      <b-input v-model.number="tariffClassCar.width" type="number"></b-input>
    </b-col>
    <b-col cols="4">
      <label for="">Высота(м): <i style="color: red;">*</i></label>
      <b-input v-model.number="tariffClassCar.height" type="number"></b-input>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="12">
      <label for="">Настройки межгорода: <i style="color: red;">*</i></label>
      <v-select
      :options="setting_intercity"
      :reduce="option => option.code"
      label="value"
      v-model="tariffClassCar.setting_intercity"
      ></v-select>
    </b-col>
  </b-row>
  <div class="d-flex mt-1" style>
    <fieldset class="border p-1 w-100">
      <legend style="font-size: 16px;" class="w-auto mb-0">Дополнительные условия</legend>
      <div class="d-flex">
        <b-form-group class="m-0" style="column-count: 2; width: 100%;">
            <b-form-checkbox
              v-for="option in dop_options"
              :key="option.id"
              v-model="tariffClassCar.dop_conditions"
              :value="option.id"
              style="margin-top: 2px;"
              :disabled="!!tariffClassCar.not_dop_conditions.find(el => el == option.id)"
            >
              {{ option.name }}
            </b-form-checkbox>
          </b-form-group>
        </div>
    </fieldset>
    <fieldset class="border p-1 w-100 ml-1">
      <legend style="font-size: 16px;" class="w-auto  mb-0">Запрещённые дополнительные условия</legend>
      <div class="d-flex">
        <b-form-group class="m-0" style="column-count: 2; width: 100%;">
            <b-form-checkbox
              v-for="option in dop_options"
              :key="option.id"
              v-model="tariffClassCar.not_dop_conditions"
              :value="option.id"
              style="margin-top: 2px;"
              :disabled="!!tariffClassCar.dop_conditions.find(el => el == option.id)"
            >
              {{ option.name }}
            </b-form-checkbox>
          </b-form-group>
        </div>
    </fieldset>
  </div>
  <b-row class="mb-1 mt-2">
    <b-col class="d-flex justify-content-end">
      <b-button :disabled="submitButtonDisabled" @click="create()" variant="primary">Добавить</b-button>
      <b-button @click="closeModal()" class="ml-1" variant="danger">Отмена</b-button>
    </b-col>
  </b-row>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  props: [
    'tariff_id'
  ],
  components: {
    vSelect,
    ToastificationContent
  },
  data() {
    return {
      tariffClassCar: {
        tariff_id: this.tariff_id,
        class_car_id: null,
        main_class: false,
        condition_id: null,
        min_count_seat: 0,
        setting_intercity: null,
        age_up_to: 0,
        width: 0,
        height: 0,
        length: 0,
        carrying_capacity: 0,
        dop_conditions: [],
        not_dop_conditions: []
      },
      dop_options: [],
      setting_intercity: [],
      car_condition: [],
      class_cars: [],
      submitButtonDisabled: false
    };
  },
  methods: {
    getData(){
      this.$http
      .get('tariff-class-car-settings/data')
      .then(res => {
        this.dop_options = res.data.dop_conditions
        this.setting_intercity = res.data.setting_intercity
        this.car_condition = res.data.car_condition
        this.class_cars = res.data.class_cars
      })
      .catch(err => {
      })
    },
    create(){
      this.tariffClassCar.main_class = this.tariffClassCar.main_class ? 1 : 0
      this.submitButtonDisabled = true
      this.$http
      .post('tariff-class-car-settings', this.tariffClassCar)
      .then(res => {
        this.closeModal()
        this.$emit('refresh')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Успех!',
            icon: 'CheckIcon',
            variant: 'success',
            text: `${res.data.message}`,
          },
        })
      })
      .catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Некорректные данные!',
            icon: 'XIcon',
            variant: 'danger',
            text: err.response.data.errors,
          },
        })
      })
      .finally(() => {
        this.submitButtonDisabled = false
      })
    },
    closeModal(){
      this.tariffClassCar = {
        tariff_id: this.tariff_id,
        class_car_id: null,
        main_class: false,
        condition_id: null,
        min_count_seat: 0,
        setting_intercity: null,
        age_up_to: 0,
        width: 0,
        height: 0,
        length: 0,
        carrying_capacity: 0,
        dop_conditions: [],
        not_dop_conditions: []
      },
      this.dop_options = [],
      this.setting_intercity = [],
      this.car_condition = [],
      this.class_cars = [],
      // for (let key in this.tariffClassCar) {
      //   this.tariffClassCar[key] = null;
      // }
      this.tariffClassCar.dop_conditions = []
      this.tariffClassCar.not_dop_conditions = []
      this.submitButtonDisabled = false
      this.$bvModal.hide(this.$route.name + 'CreateModal')
    }
  }
};
</script>

<style>
.check {
  display: flex;
  margin-top: 28px
}
</style>

